import React from "react";
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";

export const LoadingIndicator = ({ text = "" }) => (
  <View style={styles.container}>
    <View style={styles.row}>
      <ActivityIndicator />
      <Text> {text || "Loading..."}</Text>
    </View>
  </View>
);


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F5FCFF",
  },
  row: {
    flex: 1,
    flexDirection: "row",
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
  },
});

