import React, { lazy, Suspense } from "react";
import { registerRootComponent } from "expo";
import { LoadingIndicator } from "./src/components/LoadingIndicator";

const App = lazy(() => import("./App" ));

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(() => <Suspense fallback={<LoadingIndicator text="Loading application..." />}><App /></Suspense>);
